<template>
  <div class="playlist-edit-component">

    <div class="row align-items-center header">
      <div class="col-12 col-md-3">
        <div class="title">
          <router-link to="/admin/playlists">New Playlist </router-link>/
          <span v-if="playlist && playlist.title">{{playlist.title}}</span>
        </div>
      </div>
      <div class="col-8 col-md-5">
        <typeahead @trackSelected="selectTrack" @onPlay="playSearch"></typeahead>
      </div>
      <div class="col-4 col-md-4">
        <div class="typeahead-wrapper">
          <input class="name" type="text" placeholder="Playlist Name..." v-model="playlist.title"/>
          <div class="add-playlist" @click="save()">Create</div>
        </div>
      </div>
    </div>

    <div class="new-playlist">
      <div class="row">
        <div class="col-md-12">
          <div class="controls-wrapper">
            <ul>
              <b-btn v-b-modal.modal1 class="add-playlist modal-btn">Add Meta</b-btn>
              <b-btn v-b-modal.modal2 class="add-playlist modal-btn">Add Images</b-btn>
              <li class="add-playlist" @click="playlist.shuffle = !playlist.shuffle">{{playlist.shuffle ? 'Shuffle Off' : 'Shuffle On'}}</li>
              <li class="add-playlist" @click="addTop = !addTop">{{addTop ? 'Add bottom': 'Add top'}}</li>
              <li class="add-playlist" @click="playlist.published = !playlist.published">{{playlist.published ? 'Unpublish' : 'Publish'}}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 d-none d-sm-block">
          <div class="playlist-subtitle">
            Latest edit playlists
          </div>
        </div>
        <div class="col-sm-4">
          <div class="subtitle-wrapper">
            <div class="subtitle" v-if="!playlist.tracks.length">No tracks added yet :(</div>
            <div class="subtitle" v-if="playlist.tracks.length">{{playlist.tracks.length}} Tracks Added</div>
            <div class="subtitle" v-if="playlist.tracks.length">Duration: {{secondsToMinutes(playlistDuration)}}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <ul class="playlistUpdated-list d-none d-sm-block">
            <li v-for="playlist in playlistsUpdated" v-bind:key="playlist._id" @click="navigate(playlist._id)">
              <div class="playlists-list-card-img">
                <img :src="playlist.meta.exploreImagery.hdpiImageURL" alt="" v-if="playlist.meta && playlist.meta.exploreImagery && playlist.meta.exploreImagery.hdpiImageURL" />
                <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!playlist.meta || !playlist.meta.exploreImagery || !playlist.meta.exploreImagery.hdpiImageURL"/>
              </div>
              <div class="playlist-info">
                <div class="playlist-title">{{playlist.title}}</div>
                <div class="playlist-tracks">{{playlist.tracks.length}} Tracks</div>
              </div>
            </li>
          </ul>

          <dmca :playlist="playlist"></dmca>
        </div>
        <div class="col-sm-8">
          <ul class="list">
            <draggable v-model="playlist.tracks" :options="{group:'tracks'}" @start="drag=true" @end="onEnd">
              <li v-for="(track, index) in playlist.tracks" v-bind:key="track.track._id">
                <div class="track-order">
                  {{track.order}}
                </div>
                <div class="track-img">
                  <img :src="$apiUrl + '/media/artworks/' + track.track.image + '.jpg'" alt="" v-if="track.track.image"/>
                  <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!track.track.image"/>
                </div>
                <div class="track-controls">
                  <div class="icon-wrapper" @click="toggle(index)">
                    <i class="fas fa-play" v-if="!track.track.playing"></i>
                    <i class="fas fa-pause" v-if="track.track.playing"></i>
                  </div>

                  <div class="player">
                    <div class="track-title">{{track.track.title}} - {{secondsToMinutes(track.track.duration)}}</div>
                    <div class="track-artist">By - {{track.track.artist.name}}</div>
                    <div class="track-duration">Album - {{track.track.release.title}}</div>
                  </div>

                  <div class="wavesurfer" :id="'wavesurfer' + index"></div>

                  <div class="remove" @click="remove(index)">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </li>
            </draggable>
          </ul>
        </div>
      </div>
    </div>

    <b-modal id="modal1" :ok-only="true" ok-title="close" size="lg" title="Metadata" @ok="handleOkModal">
      <div class="modal-wrapper">
        <div class="row">
          <div class="col-6">
            <label>English Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Top Hits" v-model="playlist.meta.titleEnglish"/>
          </div>
          <div class="col-6">
            <label>Spanish Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Los Exitos de Ahora" v-model="playlist.meta.titleSpanish"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>English Secondary Title</label>
            <input type="text" v-model="playlist.meta.secondaryTitleEnglish"/>
          </div>
          <div class="col-6">
            <label>Spanish Secondary Title</label>
            <input type="text" v-model="playlist.meta.secondaryTitleSpanish"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>English Description</label>
            <span class="form-text text-muted">This text is for LaMusica AppleTV and web, and is used to provide a helpful summary of what the playlist contains.</span>
            <textarea class="form-control" v-model="playlist.meta.descriptionEnglish" rows="3"></textarea>
          </div>
          <div class="col-6">
            <label>Spanish Description</label>
            <span class="form-text text-muted">This text is for LaMusica AppleTV and web, and is used to provide a helpful summary of what the playlist contains.</span>
            <textarea class="form-control" v-model="playlist.meta.descriptionSpanish" rows="3"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Slug<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Please add a unique, lowercase slug for sharing & for LaMusica app/web.</span>
            <input type="text" placeholder="top-hits" v-model="playlist.meta.slug"/>
          </div>
          <div class="col-6">
            <label>Wide Orbit Companion Ad Station Name</label>
            <span class="form-text text-muted">This is the station Name on Wide Orbit that the app will use to fetch pre-roll/mid-roll advertisements.</span>
            <input type="text" placeholder="Curated-61" v-model="playlist.meta.companionAdStationId"/>
          </div>
          <div class="col-6">
            <label>Wide Orbit Companion Ad Station ID</label>
            <span class="form-text text-muted">This is the station ID on Wide Orbit that the app will use to fetch pre-roll/mid-roll advertisements.</span>
            <input type="text" placeholder="3401" v-model="playlist.meta.wideOrbitStationId"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input type="text" placeholder="Facebook Share Image Link:" v-model="playlist.meta.facebookShareImageLink"/>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <b-form-checkbox id="checkbox1" v-model="playlist.meta.hideTitle">Hide Title</b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-checkbox id="checkbox2" v-model="playlist.meta.isFeatured">Is Featured</b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-checkbox id="checkbox3" v-model="playlist.meta.isPodcast">Is Podcast</b-form-checkbox>
          </div>
        </div>
        <br />
        <AdConfigurationFormV2
          :item="playlist.meta"
          :has-wide-orbit-companion-ads="false"
          :has-audio-companion-ads="true"
          :has-web-display-ads="true"
        />
      </div>
    </b-modal>

    <b-modal id="modal2" :ok-only="true" ok-title="close" size="lg" title="Images">
      <div class="row modal-wrapper">
        <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
          <upload @imgUploaded="imgUploaded" :data="platform"></upload>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import typeahead from '../../share/typeahead'
import upload from '../../share/UploadImage'
import dmca from '../../share/dmca'
import draggable from 'vuedraggable'
import WaveSurfer from 'wavesurfer.js'
import AdConfigurationFormV2 from '../../share/AdConfigurationFormV2'

export default {
  name: 'PlaylistNew',
  components: {
    AdConfigurationFormV2,
    typeahead,
    draggable,
    upload,
    dmca
  },
  data () {
    return {
      playlist: {
        title: '',
        published: false,
        publishDate: null,
        unPublishDate: null,
        shuffle: false,
        categories: [],
        meta: {
          updatedAt: null,
          createdAt: null,
          titleEnglish: '',
          titleSpanish: '',
          secondaryTitleEnglish: '',
          secondaryTitleSpanish: '',
          slug: '',
          type: '',
          devices: [
            'ios',
            'android',
            'web',
            'appletv',
            'androidtv'
          ],
          order: '',
          hideTitle: false,
          isFeatured: false,
          isSponsored: true,
          disableAds: false,
          playlistID: '',
          facebookShareImageLink: '',
          companionAdStationId: '',
          wideOrbitStationId: '',
          iOSVideoInterstitialAdUnitId: '',
          iOSImageInterstitialAdUnitId: '',
          androidVideoInterstitialAdUnitId: '',
          androidImageInterstitialAdUnitId: '',
          hasCompanionAdMidroll: false,
          hasCompanionAdPreroll: false,
          inclusionLocations: [],
          exploreImagery: {
            '2xImageURL': '',
            '3xImageURL': '',
            mdpiImageURL: '',
            hdpiImageURL: '',
            xhdpiImageURL: '',
            xxhdpiImageURL: '',
            xxxhdpiImageURL: ''
          },
          detailViewImagery: {
            '2xImageURL': '',
            '3xImageURL': '',
            mdpiImageURL: '',
            hdpiImageURL: '',
            xhdpiImageURL: '',
            xxhdpiImageURL: '',
            xxxhdpiImageURL: ''
          },
          cardImagery: {
            '2xImageURL': '',
            '3xImageURL': ''
          },
          web_ads: {
            '300x250': [
              {
                tag: 'LaMusica_Web_ROS_300x250_300x600',
                start: null,
                disable:false,
                end: null
              },
            ],
            '728x90': [
              {
                tag: 'LaMusica_Web_ROS_728x90_320x50',
                start: null,
                end: null,
                disable:false
              }
            ],
            '320x100': [
              {
                tag: '',
                disable:false,
                start: null,
                end: null
              }
            ],
            '728x180': [
              {
                tag: '',
                disable:false,
                start: null,
                end: null
              }
            ],
            '468x60': [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }],
            disable_interstitial: false
          },
          audio_ads_settings: {
            base_url: 'https://sbswo.deliveryengine.adswizz.com/vast/4.0/request/alias/',
            preroll_zone_alias: '',
            midroll_zone_alias: '',
            display_zone_alias: '',
            genre: null,
            duration_preroll: '16000',
            duration_midroll: '16000',
            language: 'SP',
            has_pre_roll: false,
            has_mid_roll: false
          },
          video_ads_settings: {
            isSponsored: true,
            sponsored: {
              start: null,
              end: null
            },
            disableAds: false,
            DFPPrerollVideoAdUnitId: null,
            WebVideoAdUnitId: null,
            AmazonTVVideoAdUnitId: null,
            AndroidTVVideoAdUnitId: null,
            RokuTVVideoAdUnitId: null,
            AppleTVVideoAdUnitId: null
          },
        },
        tracks: []
      },
      playlistID: '',
      image: '',
      audio: null,
      addTop: false,
      playlistDuration: 0,
      platforms: {
        android: {
          label: 'Android - 1296x560px',
          platform: 'android',
          isLoading: false,
          width: 1296,
          height: 560,
          url: ''
        },
        ios: {
          label: 'iOS - 1092x432px',
          platform: 'ios',
          isLoading: false,
          width: 1092,
          height: 432,
          url: ''
        },
        exploreSquare: {
          label: 'Detail - 1440x1440px',
          platform: 'exploreSquare',
          isLoading: false,
          width: 1440,
          height: 1440,
          url: ''
        },
        card: {
          label: 'Card - 1000x1100px',
          platform: 'card',
          isLoading: false,
          width: 1000,
          height: 1100,
          url: ''
        },
        landing: {
          label: 'Featured Header - 1920x850',
          platform: 'landing',
          description: 'This will be used on the tv and homepage website rotators',
          isLoading: false,
          width: 1920,
          height: 850,
          url: ''
        }
      },
      playlistsUpdated: [],
      searchPlaying: false,
      searchTrackPlaying: null
    }
  },

  mounted () {
    this.$http.get('/playlists', {params: {limit: 40, page: 1}})
      .then((res) => {
        this.playlistsUpdated = res.data.playlists
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    // validateCheckBox (object) {
    //     if(object.btn===0 && object.value){
    //       this.playlist.meta.isSponsored = false
    //     }else if(object.btn===1 && object.value) {
    //       this.playlist.meta.hasCompanionAdPreroll = false
    //     }
    // },
    onEnd () {
      let newList = this.playlist.tracks.map((track, index) => {
        return {
          order: index,
          track: track.track
        }
      })
      this.playlist.tracks = newList
    },
    imgUploaded (formData, data) {
      this.image = formData
      data.isLoading = true
      this.$http.post(`/common/image?resize=${data.platform}`,
        this.image,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then((res) => {
          this.platforms[res.data.device]['url'] = res.data.images[0]
          switch (res.data.device) {
            case 'android':
              this.playlist.meta.exploreImagery.xxxhdpiImageURL = res.data.images[0]
              this.playlist.meta.exploreImagery.xxhdpiImageURL = res.data.images[1]
              this.playlist.meta.exploreImagery.xhdpiImageURL = res.data.images[2]
              this.playlist.meta.exploreImagery.hdpiImageURL = res.data.images[3]
              this.playlist.meta.exploreImagery.mdpiImageURL = res.data.images[4]
              break
            case 'ios':
              this.playlist.meta.exploreImagery['3xImageURL'] = res.data.images[0]
              this.playlist.meta.exploreImagery['2xImageURL'] = res.data.images[1]
              break
            case 'exploreSquare':
              this.playlist.meta.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
              this.playlist.meta.detailViewImagery.xxhdpiImageURL = res.data.images[1]
              this.playlist.meta.detailViewImagery.xhdpiImageURL = res.data.images[2]
              this.playlist.meta.detailViewImagery.hdpiImageURL = res.data.images[3]
              this.playlist.meta.detailViewImagery.mdpiImageURL = res.data.images[4]
              this.playlist.meta.detailViewImagery['3xImageURL'] = res.data.images[5]
              this.playlist.meta.detailViewImagery['2xImageURL'] = res.data.images[6]
              break
            case 'card':
              this.playlist.meta.cardImagery['3xImageURL'] = res.data.images[0]
              this.playlist.meta.cardImagery['2xImageURL'] = res.data.images[1]
              break
            case 'landing':
              this.playlist.meta.landing = {}
              this.playlist.meta.landing['3xImageURL'] = res.data.images[0]
              this.playlist.meta.landing['2xImageURL'] = res.data.images[1]
              this.playlist.meta.landing['1xImageURL'] = res.data.images[2]
              break
          }
          data.isLoading = false
        })
        .catch((e) => {
          console.log('FAILURE!!', e)
        })
    },
    selectTrack (track) {
      this.$set(track, 'playing', false)
      if (!this.contains(this.playlist.tracks, track)) {
        let trackToAdd = {
          order: 0,
          track: track
        }
        if (this.addTop) {
          let newList = this.playlist.tracks.map((track, index) => {
            return {
              order: index + 1,
              track: track.track
            }
          })
          this.playlist.tracks = newList
          this.playlist.tracks.unshift(trackToAdd)
        } else {
          trackToAdd.order = this.playlist.tracks.length
          this.playlist.tracks.push(trackToAdd)
        }
        this.playlistDuration += parseInt(track.duration)
      } else {
        this.$notify({
          group: 'notifications',
          title: 'Track already added to playlist',
          text: '',
          type: 'error'
        })
      }

      EventHandler.emit('close-typeahead')
    },
    playSearch (track, index) {
      if (this.searchPlaying && track._id === this.searchTrackPlaying) {
        this.wavesurfer.pause()
        this.searchPlaying = false
        return
      }
      if (this.wavesurfer) {
        this.wavesurfer.empty()
        this.wavesurfer.destroy()

        this.playlist.tracks.forEach((track, i) => {
          if (track.track.playing) {
            track.track.playing = false
          }
        })
      }

      this.wavesurfer = WaveSurfer.create({
        container: `#wavesurfer-typeahead-${index}`,
        height: '60',
        backend: 'MediaElement',
        waveColor: '#FD8283',
        progressColor: '#f2494a',
        responsive: true,
        barWidth: 2
      })

      this.wavesurfer.load(`${this.$apiUrl}/media/tracks/${track._id}.m4a`)
      this.wavesurfer.play()
      this.searchPlaying = true
      this.searchTrackPlaying = track._id
    },
    toggle (index) {
      this.playlist.tracks.forEach((track, i) => {
        if (track.track.playing && index !== i) {
          track.track.playing = false
          this.wavesurfer.pause()
          this.wavesurfer.empty()
          this.wavesurfer.destroy()
        }
      })

      if (this.playlist.tracks[index].track.playing) {
        this.playlist.tracks[index].track.playing = false
        this.wavesurfer.pause()
        this.wavesurfer.empty()
        this.wavesurfer.destroy()
      } else {
        this.wavesurfer = WaveSurfer.create({
          container: `#wavesurfer${index}`,
          height: '60',
          backend: 'MediaElement',
          waveColor: '#FD8283',
          progressColor: '#f2494a',
          responsive: true,
          barWidth: 2
        })

        this.wavesurfer.load(`${this.$apiUrl}/media/tracks/${this.playlist.tracks[index].track._id}.m4a`)
        this.wavesurfer.play()
        this.playlist.tracks[index].track.playing = true
      }
    },
    save () {
      let missingImage = {
        label: null,
        required: false
      }

      if (this.playlist.title === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing Playlist Title :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.playlist.published) {
        for (const key in this.platforms) {
          if (this.platforms.hasOwnProperty(key)) {
            const element = this.platforms[key]

            if (element.platform !== 'card' && element.url === '') {
              missingImage.label = element.label
              missingImage.required = true
              break
            }
          }
        }
      }

      if (missingImage.required) {
        return this.$notify({
          group: 'notifications',
          title: `${missingImage.label} image is required :(`,
          text: '',
          type: 'error'
        })
      }

      let playlist = JSON.parse(JSON.stringify(this.playlist))

      playlist.tracks = playlist.tracks.map(track => {
        let obj = {}
        obj['order'] = track.order
        obj['trackId'] = track.track._id
        obj['elastic'] = {
          title: track.track.title,
          artist: track.track.artist.name
        }

        return obj
      })

      this.$http.post('/playlists', {playlist})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Playlist created successfully :)',
            text: '',
            type: 'success'
          })
          this.$router.push('/admin/playlists/edit/' + res.data.id)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    remove (index) {
      if (this.playlist.tracks[index].playing) {
        this.playlist.tracks[index].playing = false
        this.wavesurfer.pause()
        this.wavesurfer.empty()
        this.wavesurfer.destroy()
      }
      this.playlistDuration -= parseInt(this.playlist.tracks[index].track.duration)
      this.playlist.tracks.splice(index, 1)
    },
    navigate (id) {
      this.$router.push('/admin/playlists/edit/' + id)
    },
    secondsToMinutes (secs) {
      let secNum = parseInt(secs, 10)
      let hours = Math.floor(secNum / 3600) % 24
      let minutes = Math.floor(secNum / 60) % 60
      let seconds = secNum % 60
      return [hours, minutes, seconds]
        .map(v => v < 10 ? '0' + v : v)
        .filter((v, i) => v !== '00' || i > 0)
        .join(':')
    },
    contains (arr, obj) {
      let findIt = false
      arr.forEach(function (element, index) {
        if (arr[index].track._id === obj._id) {
          findIt = true
        }
      })

      if (findIt) {
        return true
      }

      return false
    },
    handleOkModal () {
      console.log('Modal closed')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.playlist-edit-component .header{
  margin-bottom: 30px;
}

.playlist-edit-component .title{
  margin-bottom: 0;
}

.typeahead-wrapper-tracks{
  margin-bottom: 15px;
}

.typeahead-wrapper {
  display: flex;
}

.typeahead-wrapper .add-playlist{
  width: 150px;
  line-height: 45px;
  text-align: center;
  background: #f2494a;
  margin-left: 15px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.typeahead-wrapper .name{
    flex: 1;
    height: 45px;
    padding: 0 45px;
    border: none;
    font-size: 14px;
    border: 1px solid #eaeaea;
}

.playlist-subtitle{
  text-transform: uppercase;
}

.subtitle-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.subtitle-wrapper .subtitle{
  text-transform: uppercase;
  margin-right: 25px;
}

.subtitle-wrapper .subtitle:last-child{
  margin-right: 0;
}

.new-playlist ul.list, ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    height: -moz-calc(100vh - 360px);
    height: -webkit-calc(100vh - 360px);
    height: calc(100vh - 360px);
    padding: 3px;
    margin-bottom: 20px;
}

.new-playlist ul.list, ul.playlistUpdated-list .playlist-title{
  font-size: 12px;
  font-weight: bold;
}

.new-playlist ul.list, ul.playlistUpdated-list .playlist-tracks{
  font-size: 10px;
  font-weight: bold;
}

.new-playlist ul.list{
  height: -moz-calc(100vh - 220px);
  height: -webkit-calc(100vh - 220px);
  height: calc(100vh - 220px);
}

.new-playlist ul.list li{
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 60px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
}


.new-playlist ul li .track-order{
  min-width: 60px;
  height: 60px;
  text-align: center;
  font-size: 12px;
  line-height: 60px;
  font-weight: 900
}

.modal-wrapper label {
  margin-bottom: 0;
  font-weight: bold;
}

.new-playlist ul li img{
    width: 60px;
    cursor: move;
}

.new-playlist .track-controls{
    display: flex;
    flex: 1;
    align-items: center;
}

.new-playlist .track-controls .icon-wrapper, .new-playlist .track-controls .remove{
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
}

.new-playlist .track-controls .player{
    margin-right: 15px;
}

.new-playlist .track-controls .player .track-title{
  text-transform: capitalize;
  font-size: 12px;
  font-weight: bold;
}

.new-playlist .track-controls .player .track-artist,
.new-playlist .track-controls .player .track-duration{
  font-size: 10px;
}

.new-playlist .track-controls .player .track-title{
  text-transform: capitalize;
}

.wavesurfer{
  flex: 1;
}

.wavesurfer canvas{
  max-width: none;
}

ul.playlistUpdated-list li {
  list-style-type: none;
  display: flex;
  background-color: #fff;
  margin-bottom: 10px;
  height: 60px;
  align-items: center;
  border: 1px solid #eaeaea;
  cursor: pointer;
}

ul.playlistUpdated-list li .playlists-list-card-img {
  margin-right: 15px;
}

ul.playlistUpdated-list li .playlists-list-card-img img{
  height: 60px;
  width: auto;
}

@media (max-width: 768px) {
  .playlistUpdated-list {
    display: none;
  }

  .typeahead-wrapper input {
    display: none;
  }

  .playlist-edit-component .title {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .typeahead-wrapper .add-playlist {
    margin-left: 0;
  }

  .controls-wrapper .modal-btn,
  .controls-wrapper ul li {
    font-size: 10px;
    padding: 5px 10px;
  }

  .new-playlist ul.list {
    margin-top: 20px;
    padding: 0 10px;
  }

  .track-img {
    display: none;
  }

  .track-duration {
    display: none;
  }

  .new-playlist ul li .track-order {
    min-width: 40px;
  }

  .new-playlist .track-controls .player .track-title {
    font-size: 10px;
  }
}

</style>
