<template>
  <div class="playlist-edit-component">
    <div class="row align-items-center header">
      <div class="col-12 col-md-3">
        <div class="title">
          <router-link to="/admin/playlists">Playlists </router-link>/
          <span v-if="playlist && playlist.title">{{playlist.title}}</span>
        </div>
      </div>
      <div class="col-8 col-md-5">
        <typeahead @trackSelected="selectTrack" @onPlay="playSearch"></typeahead>
      </div>
      <div class="col-4 col-md-4">
        <div class="typeahead-wrapper">
          <input class="name" type="text" placeholder="Playlist Name..." v-model="playlist.title"/>
          <div class="add-playlist" @click="save()">Update</div>
        </div>
      </div>
    </div>

    <div class="new-playlist">
      <div class="row">
        <div class="col-md-12">
          <div class="controls-wrapper">
            <ul>
              <li class="add-playlist" v-if="hasMediacopied" @click="pasteMedia">Paste</li>
              <b-btn v-b-modal.modal1 class="add-playlist modal-btn">Add Meta</b-btn>
              <b-btn v-b-modal.modal2 class="add-playlist modal-btn">Add Images</b-btn>
              <li class="add-playlist" @click="playlist.shuffle = !playlist.shuffle">{{playlist.shuffle ? 'Shuffle Off' : 'Shuffle On'}}</li>
              <li class="add-playlist" @click="addTop = !addTop">{{addTop ? 'Add bottom': 'Add top'}}</li>
              <li class="add-playlist" @click="playlist.published = !playlist.published">{{playlist.published ? 'Unpublish' : 'Publish'}}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 d-none d-sm-block">
          <div class="playlist-subtitle">
            Latest edit playlists
          </div>
        </div>
        <div class="col-sm-4">
          <div class="subtitle-wrapper">
            <div class="subtitle" v-if="!playlist.tracks.length">No tracks added yet :(</div>
            <div class="subtitle" v-if="playlist.tracks.length">{{playlist.tracks.length}} Tracks Added</div>
            <div class="subtitle" v-if="playlist.tracks.length">Duration: {{finalsecondsToMinutes(playlistDuration)}}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 latest-playlist-list">
          <ul class="playlistUpdated-list d-none d-sm-block">
            <li v-for="playlist in playlistsUpdated" v-bind:key="playlist._id" @click="navigate(playlist._id)">
              <div class="playlists-list-card-img">
                <img :src="playlist.meta.exploreImagery.hdpiImageURL" alt="" v-if="playlist.meta && playlist.meta.exploreImagery && playlist.meta.exploreImagery.hdpiImageURL" />
                <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!playlist.meta || !playlist.meta.exploreImagery || !playlist.meta.exploreImagery.hdpiImageURL"/>
              </div>
              <div class="playlist-info">
                <div class="playlist-title">{{playlist.title}}</div>
                <div class="playlist-tracks">{{playlist.tracks.length}} Tracks</div>
              </div>
            </li>
          </ul>
          <dmca :playlist="playlist"></dmca>
        </div>

        <div class="col-md-8 playlist-list-wrapper">
          <ul class="list">
            <draggable v-model="playlist.tracks" :options="{group:'tracks'}" @start="drag=true" @end="onEnd">
              <li v-for="(track, index) in playlist.tracks" v-bind:key="track.track._id">
                <div class="track-order">
                  {{track.order}}
                </div>
                <div class="track-img">
                  <img :src="$apiUrl + '/media/artworks/' + track.track.image + '.jpg'" alt="" v-if="track.track.image"/>
                  <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!track.track.image"/>
                </div>
                <div class="track-controls">
                  <div class="icon-wrapper" @click="toggle(index)">
                    <i class="fas fa-play" v-if="!track.track.playing"></i>
                    <i class="fas fa-pause" v-if="track.track.playing"></i>
                  </div>

                  <div class="player">
                    <div class="track-title">{{track.track.title}} - {{secondsToMinutes(track.track.duration)}}</div>
                    <div class="track-artist">By - {{track.track.artist.name}}</div>
                    <div class="track-duration">Album - {{track.track.isPodcast ? formatDate(track.track.release.date) : track.track.release.title}}</div>
                  </div>

                  <div class="wavesurfer" :id="'wavesurfer' + index"></div>

                  <div class="copy icons-media" @click="copyMedia(track)">
                    <i class="fas fa-copy"></i>
                  </div>

                  <div class="remove icons-media" @click="remove(index)">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </li>
            </draggable>
          </ul>
        </div>
      </div>
    </div>

    <b-modal id="modal1" :ok-only="true" ok-title="close" size="lg" title="Metadata" @ok="handleOkModal">
      <div class="modal-wrapper">
        <div class="row">
          <div class="col-6">
            <label>English Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Top Hits" v-model="playlist.meta.titleEnglish"/>
          </div>
          <div class="col-6">
            <label>Spanish Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Los Exitos de Ahora" v-model="playlist.meta.titleSpanish"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>English Secondary Title</label>
            <input type="text" v-model="playlist.meta.secondaryTitleEnglish"/>
          </div>
          <div class="col-6">
            <label>Spanish Secondary Title</label>
            <input type="text" v-model="playlist.meta.secondaryTitleSpanish"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>English Description</label>
            <span class="form-text text-muted">This text is for LaMusica AppleTV and web, and is used to provide a helpful summary of what the playlist contains.</span>
            <textarea class="form-control" v-model="playlist.meta.descriptionEnglish" rows="3"></textarea>
          </div>
          <div class="col-6">
            <label>Spanish Description</label>
            <span class="form-text text-muted">This text is for LaMusica AppleTV and web, and is used to provide a helpful summary of what the playlist contains.</span>
            <textarea class="form-control" v-model="playlist.meta.descriptionSpanish" rows="3"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Slug<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Please add a unique, lowercase slug for sharing & for LaMusica app/web.</span>
            <input type="text" placeholder="top-hits" v-model="playlist.meta.slug"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input type="text" placeholder="Facebook Share Image Link:" v-model="playlist.meta.facebookShareImageLink"/>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <b-form-checkbox id="checkbox1" v-model="playlist.meta.hideTitle">Hide Title</b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-checkbox id="checkbox2" v-model="playlist.meta.isFeatured">Is Featured</b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-checkbox id="checkbox3" v-model="playlist.meta.isPodcast">Is Podcast</b-form-checkbox>
          </div>
        </div>
        <br />
        <AdConfigurationFormV2
            :item="playlist.meta"
            :has-wide-orbit-companion-ads="false"
            :has-audio-companion-ads="true"
            :has-web-display-ads="true"
        />
      </div>
    </b-modal>

    <b-modal id="modal2" :ok-only="true" ok-title="close" size="lg" title="Images">
      <div class="row modal-wrapper">
        <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
          <upload @imgUploaded="imgUploaded" :data="platform"></upload>
        </div>
      </div>
    </b-modal>

    <b-modal id="modalClipboard" ref="modalClipboard"  size="md" title="Clear Clipboard" @ok="clearClipboard()">
      <div class="row modal-wrapper">
        <div class="col-12">
          Would you like to delete the songs on your clipboard?
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import typeahead from '../../share/typeahead'
  import upload from '../../share/UploadImage'
  import dmca from '../../share/dmca'
  import draggable from 'vuedraggable'
  import WaveSurfer from 'wavesurfer.js'
  import axios from 'axios'
  import moment from 'moment'
  import AdConfigurationFormV2 from '../../share/AdConfigurationFormV2'

export default {
    name: 'PlaylistEdit',
    components: {
      AdConfigurationFormV2,
      typeahead,
      draggable,
      upload,
      dmca
    },
    data () {
      return {
        playlist: {
          title: '',
          published: false,
          publishDate: null,
          unPublishDate: null,
          shuffle: false,
          categories: [],
          meta: {
            updatedAt: null,
            createdAt: null,
            titleEnglish: '',
            titleSpanish: '',
            secondaryTitleEnglish: '',
            secondaryTitleSpanish: '',
            descriptionEnglish: '',
            descriptionSpanish: '',
            slug: '',
            type: '',
            devices: [
              'ios',
              'android',
              'web',
              'appletv',
              'androidtv'
            ],
            order: '',
            hideTitle: true,
            isFeatured: false,
            isSponsored: false,
            disableAds: false,
            isPodcast: false,
            playlistID: '',
            facebookShareImageLink: '',
            companionAdStationId: '',
            wideOrbitStationId: '',
            iOSVideoInterstitialAdUnitId: '',
            iOSImageInterstitialAdUnitId: '',
            androidVideoInterstitialAdUnitId: '',
            androidImageInterstitialAdUnitId: '',
            hasCompanionAdMidroll: false,
            hasCompanionAdPreroll: false,
            inclusionLocations: [],
            exploreImagery: {
              '2xImageURL': '',
              '3xImageURL': '',
              mdpiImageURL: '',
              hdpiImageURL: '',
              xhdpiImageURL: '',
              xxhdpiImageURL: '',
              xxxhdpiImageURL: ''
            },
            detailViewImagery: {
              '2xImageURL': '',
              '3xImageURL': '',
              mdpiImageURL: '',
              hdpiImageURL: '',
              xhdpiImageURL: '',
              xxhdpiImageURL: '',
              xxxhdpiImageURL: ''
            },
            cardImagery: {
              '2xImageURL': '',
              '3xImageURL': ''
            },
            audio_ads_settings: {
              base_url: null,
              preroll_zone_alias: null,
              midroll_zone_alias: null,
              display_zone_alias: null,
              genre: null,
              duration_preroll: null,
              duration_midroll: null,
              language: null,
              has_pre_roll: false,
              has_mid_roll: false
            },
            video_ads_settings: {
              isSponsored: true,
              sponsored: {
                start: null,
                end: null
              },
              disableAds: false,
              DFPPrerollVideoAdUnitId: null,
              WebVideoAdUnitId: null,
              AmazonTVVideoAdUnitId: null,
              AndroidTVVideoAdUnitId: null,
              RokuTVVideoAdUnitId: null,
              AppleTVVideoAdUnitId: null
            },
          },
          tracks: []
        },
        playlistID: '',
        image: '',
        audio: null,
        addTop: false,
        playlistDuration: 0,
        platforms: {
          android: {
            label: 'Android - 1296x560px',
            description: 'This will be used on the playlists section of LaMusica app/web',
            platform: 'android',
            width: 1296,
            height: 560,
            url: ''
          },
          ios: {
            label: 'iOS - 1092x432px',
            description: 'This will be used on the playlists section of LaMusica app/web',
            platform: 'ios',
            width: 1092,
            height: 432,
            url: ''
          },
          exploreSquare: {
            label: 'Detail - 1440x1440px',
            platform: 'exploreSquare',
            description: 'This will be used on the detail view (app/web) and homepage card rows (app)',
            width: 1440,
            height: 1440,
            url: ''
          },
          card: {
            label: 'Card - 1000x1100px',
            platform: 'card',
            description: 'This will be used on the featured card row (app)',
            width: 1000,
            height: 1100,
            url: ''
          },
          landing: {
            label: 'Featured Header - 1920x850',
            platform: 'landing',
            description: 'This will be used on the tv and homepage website rotators',
            isLoading: false,
            width: 1920,
            height: 850,
            url: ''
          }
        },
        playlistsUpdated: [],
        searchPlaying: false,
        searchTrackPlaying: null,
        copiedTracks: JSON.parse(localStorage.getItem('copiedTracks')) || [],
        hasMediacopied: false
      }
    },

    mounted () {
      this.playlistID = this.$route.params.id
      this.hasMediacopied = (this.copiedTracks.length > 0) || false
      for (let key in this.platforms) {
        this.platforms[key].uploadURL = `/common/image?resize=${this.platforms[key].platform}`
      }

      axios.all([this.getPlaylist(), this.getPlaylistsUpdated()])
        .then(axios.spread((playlist, playlistsUpdated) => {
          this.playlistsUpdated = playlistsUpdated.data.playlists
          this.playlist = playlist.data[0]
          const meta = this.playlist.meta
          this.platforms.android.url = (meta.exploreImagery && meta.exploreImagery.xxxhdpiImageURL) ? meta.exploreImagery.xxxhdpiImageURL : ''
          this.platforms.ios.url = (meta.exploreImagery && meta.exploreImagery['3xImageURL']) ? meta.exploreImagery['3xImageURL'] : ''
          this.platforms.exploreSquare.url = (meta.detailViewImagery && meta.detailViewImagery.xxxhdpiImageURL) ? meta.detailViewImagery.xxxhdpiImageURL : ''
          this.platforms.card.url = (meta.cardImagery && meta.cardImagery['3xImageURL']) ? meta.cardImagery['3xImageURL'] : ''
          this.platforms.landing.url = (meta.landing && meta.landing['3xImageURL']) ? meta.landing['3xImageURL'] : ''

          this.playlist.tracks.forEach((track, index) => {
            this.playlistDuration += parseInt(track.track.duration)
            this.$set(this.playlist.tracks[index].track, 'playing', false)
          })

          if (!this.playlist.meta.web_ads['320x100']) {
            this.playlist.meta['web_ads']['320x100'] = [{
                tag: '',
                disable:false,
                start: null,
                end: null
              }]
          }
          if (!this.playlist.meta.web_ads['728x180']) {
            this.playlist.meta['web_ads']['728x180'] = [{
                tag: '',
                disable:false,
                start: null,
                end: null
              }]
          }

          if (!this.playlist.meta.web_ads['468x60']) {
            this.playlist.meta['web_ads']['468x60'] = [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }]
          }

          if(typeof this.playlist.meta.web_ads.disable_interstitial === 'undefined') {
            this.playlist.meta.web_ads.disable_interstitial = false
          }

          if(!this.playlist.meta.audio_ads_settings) {
            this.$set(this.playlist.meta, 'audio_ads_settings', {
              base_url: 'https://sbswo.deliveryengine.adswizz.com/vast/4.0/request/alias/',
              preroll_zone_alias: '',
              midroll_zone_alias: '',
              display_zone_alias: '',
              genre: null,
              duration_preroll: '16000',
              duration_midroll: '16000',
              language: 'SP',
              has_pre_roll: false,
              has_mid_roll: false
            })
          }

          if(!this.playlist.meta.video_ads_settings) {
            this.$set(this.playlist.meta, 'video_ads_settings', {
              isSponsored: true,
              sponsored: {
                start: null,
                end: null
              },
              disableAds: false,
              DFPPrerollVideoAdUnitId: null,
              WebVideoAdUnitId: null,
              AmazonTVVideoAdUnitId: null,
              AndroidTVVideoAdUnitId: null,
              RokuTVVideoAdUnitId: null,
              AppleTVVideoAdUnitId: null
            })
          }
        }))
    },
    methods: {
      // validateCheckBox (object) {
      //   if(object.btn===0 && object.value){
      //     this.playlist.meta.isSponsored = false
      //   }else if(object.btn===1 && object.value) {
      //     this.playlist.meta.hasCompanionAdPreroll = false
      //   }
      // },
      clearClipboard () {
        localStorage.removeItem('copiedTracks')
        this.hasMediacopied = false
      },
      copyMedia (track) {
        let mediasCopied = JSON.parse(localStorage.getItem('copiedTracks'))
        if (mediasCopied && mediasCopied.length > 0) {
          let containMedia = this.contains(mediasCopied, track.track)
          if (containMedia) {
            this.$notify({
              group: 'notifications',
              title: `Track: ${track.track.title} already copied`,
              text: '',
              type: 'error'
            })
            return
          }
        }

        this.copiedTracks.push(track)
        localStorage.setItem('copiedTracks', JSON.stringify(this.copiedTracks))
        this.$notify({
          group: 'notifications',
          title: `Copied track: ${track.track.title} successfully`,
          text: '',
          type: 'success'
        })
        this.hasMediacopied = true
      },
      pasteMedia () {
        let mediasCopied = JSON.parse(localStorage.getItem('copiedTracks'))
        let mediaToPaste = []

        mediasCopied.forEach((media) => {
          let containMedia = this.contains(this.playlist.tracks, media.track)
          if (!containMedia) {
            mediaToPaste.push(media)
          }
        })

        if (mediaToPaste.length > 0) {
          if (!this.addTop) {
            this.playlist.tracks = this.playlist.tracks.concat(mediaToPaste)
          } else {
            this.playlist.tracks = mediaToPaste.concat(this.playlist.tracks)
          }

          this.$notify({
            group: 'notifications',
            title: `New tracks added successfully`,
            text: '',
            type: 'success'
          })
        }

        this.$refs.modalClipboard.show()
      },
      getPlaylist () {
        return this.$http.get(`/playlists/${this.playlistID}`)
      },
      getPlaylistsUpdated () {
        return this.$http.get('/playlists', {params: {limit: 40, page: 1}})
      },
      onEnd () {
        let newList = this.playlist.tracks.map((track, index) => {
          return {
            order: index,
            track: track.track
          }
        })
        this.playlist.tracks = newList
      },
      imgUploaded (data, res) {
        this.platforms[res.data.device]['url'] = res.data.images[0]
        const imageKeys = ['exploreImagery', 'detailViewImagery', 'cardImagery', 'landing']
        imageKeys.forEach((key) => {
          if (!this.playlist.meta[key]) {
            this.playlist.meta[key] = {}
          }
        })
        switch (res.data.device) {
          case 'android':
            this.playlist.meta.exploreImagery.xxxhdpiImageURL = res.data.images[0]
            this.playlist.meta.exploreImagery.xxhdpiImageURL = res.data.images[1]
            this.playlist.meta.exploreImagery.xhdpiImageURL = res.data.images[2]
            this.playlist.meta.exploreImagery.hdpiImageURL = res.data.images[3]
            this.playlist.meta.exploreImagery.mdpiImageURL = res.data.images[4]
            break
          case 'ios':
            this.playlist.meta.exploreImagery['3xImageURL'] = res.data.images[0]
            this.playlist.meta.exploreImagery['2xImageURL'] = res.data.images[1]
            break
          case 'exploreSquare':
            this.playlist.meta.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
            this.playlist.meta.detailViewImagery.xxhdpiImageURL = res.data.images[1]
            this.playlist.meta.detailViewImagery.xhdpiImageURL = res.data.images[2]
            this.playlist.meta.detailViewImagery.hdpiImageURL = res.data.images[3]
            this.playlist.meta.detailViewImagery.mdpiImageURL = res.data.images[4]
            this.playlist.meta.detailViewImagery['3xImageURL'] = res.data.images[5]
            this.playlist.meta.detailViewImagery['2xImageURL'] = res.data.images[6]
            break
          case 'card':
            this.playlist.meta.cardImagery['3xImageURL'] = res.data.images[0]
            this.playlist.meta.cardImagery['2xImageURL'] = res.data.images[1]
            break
          case 'landing':
            this.playlist.meta.landing = {}
            this.playlist.meta.landing['3xImageURL'] = res.data.images[0]
            this.playlist.meta.landing['2xImageURL'] = res.data.images[1]
            this.playlist.meta.landing['1xImageURL'] = res.data.images[2]
            break
        }
        this.platforms[res.data.device].isLoading = false
      },
      selectTrack (track) {
        this.$set(track, 'playing', false)
        if (!this.contains(this.playlist.tracks, track)) {
          let trackToAdd = {
            order: 0,
            track: track
          }
          if (this.addTop) {
            let newList = this.playlist.tracks.map((track, index) => {
              return {
                order: index + 1,
                track: track.track
              }
            })
            this.playlist.tracks = newList
            this.playlist.tracks.unshift(trackToAdd)
          } else {
            trackToAdd.order = this.playlist.tracks.length
            this.playlist.tracks.push(trackToAdd)
          }
          this.playlistDuration += parseInt(track.duration)
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Track already added to playlist',
            text: '',
            type: 'error'
          })
        }

        EventHandler.emit('close-typeahead')
      },
      playSearch (track, index) {
        if (this.searchPlaying && track._id === this.searchTrackPlaying) {
          this.wavesurfer.pause()
          this.searchPlaying = false
          return
        }
        if (this.wavesurfer) {
          this.wavesurfer.empty()
          this.wavesurfer.destroy()

          this.playlist.tracks.forEach((track, i) => {
            if (track.track.playing) {
              track.track.playing = false
            }
          })
        }

        this.wavesurfer = WaveSurfer.create({
          container: `#wavesurfer-typeahead-${index}`,
          height: '60',
          backend: 'MediaElement',
          waveColor: '#FD8283',
          progressColor: '#f2494a',
          responsive: true,
          barWidth: 2
        })

        this.wavesurfer.load(`${this.$apiUrl}/media/tracks/${track._id}.m4a`)
        this.wavesurfer.play()
        this.searchPlaying = true
        this.searchTrackPlaying = track._id
      },
      toggle (index) {
        EventHandler.emit('close-typeahead')
        this.playlist.tracks.forEach((track, i) => {
          if (track.track.playing && index !== i) {
            track.track.playing = false
            this.wavesurfer.pause()
            this.wavesurfer.empty()
            this.wavesurfer.destroy()
          }
        })
        if (this.playlist.tracks[index].track.playing) {
          this.playlist.tracks[index].track.playing = false
          this.wavesurfer.pause()
          this.wavesurfer.empty()
          this.wavesurfer.destroy()
        } else {
          this.wavesurfer = WaveSurfer.create({
            container: `#wavesurfer${index}`,
            height: '60',
            backend: 'MediaElement',
            waveColor: '#FD8283',
            progressColor: '#f2494a',
            responsive: true,
            barWidth: 2
          })

          this.wavesurfer.load(`${this.$apiUrl}/media/tracks/${this.playlist.tracks[index].track._id}.m4a`)
          this.wavesurfer.play()
          this.playlist.tracks[index].track.playing = true
        }
      },
      save () {
        let missingImage = {
          label: null,
          required: false
        }

        if (this.playlist.published) {
          for (const key in this.platforms) {
            if (this.platforms.hasOwnProperty(key)) {
              const element = this.platforms[key]

              if (element.platform !== 'card' && element.url === '') {
                missingImage.label = element.label
                missingImage.required = true
                break
              }
            }
          }
        }

        if (missingImage.required) {
          return this.$notify({
            group: 'notifications',
            title: `${missingImage.label} image is required :(`,
            text: '',
            type: 'error'
          })
        }

        if (this.playlist.title === '' || !this.playlist.meta.titleEnglish || this.playlist.meta.titleEnglish === '' || !this.playlist.meta.titleSpanish || this.playlist.meta.titleSpanish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing Playlist Title :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (!this.playlist.meta.slug || this.playlist.meta.slug === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing Playlist Slug :(',
            text: '',
            type: 'error'
          })
          return
        }

        let playlist = JSON.parse(JSON.stringify(this.playlist))
        playlist.tracks = playlist.tracks.map(track => {
          let obj = {}
          obj['order'] = track.order
          obj['trackId'] = track.track._id
          obj['elastic'] = {
            title: track.track.title,
            artist: track.track.artist.name
          }
          return obj
        })

        this.$http.put('/playlists', {playlist})
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: 'Playlist updated successfully :)',
              text: '',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      remove (index) {
        if (this.playlist.tracks[index].playing) {
          this.playlist.tracks[index].playing = false
          this.wavesurfer.pause()
          this.wavesurfer.empty()
          this.wavesurfer.destroy()
        }
        this.playlistDuration -= parseInt(this.playlist.tracks[index].track.duration)
        this.playlist.tracks.splice(index, 1)
      },
      navigate (id) {
        this.$router.push('/admin/playlists/edit/' + id)
      },
      secondsToMinutes (secs) {
        let secNum = parseInt(secs, 10)
        let hours = Math.floor(secNum / 3600) % 24
        let minutes = Math.floor(secNum / 60) % 60
        let seconds = secNum % 60
        return [hours, minutes, seconds]
          .map(v => v < 10 ? '0' + v : v)
          .filter((v, i) => v !== '00' || i > 0)
          .join(':')
      },
      finalsecondsToMinutes(secs){
        let secNum = parseInt(secs, 10)
        var hours   = Math.floor(secNum / 3600)
        var minutes = Math.floor(secNum / 60) % 60
        var seconds = secNum % 60

        return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
      },
      // TODO move to a helper file
      contains (arr, obj) {
        let findIt = false
        arr.forEach(function (element, index) {
          if (arr[index].track._id === obj._id) {
            findIt = true
          }
        })

        if (findIt) {
          return true
        }

        return false
      },
      handleOkModal () {
        console.log('Modal closed')
      },
      formatDate (date) {
        return moment(date).format('dddd MMMM Do, YYYY')
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .playlist-edit-component .header{
    margin-bottom: 30px;
  }

  .playlist-edit-component .title{
    margin-bottom: 0;
  }

  .typeahead-wrapper-tracks{
    margin-bottom: 15px;
  }

  .typeahead-wrapper {
    display: flex;
  }

  .typeahead-wrapper .add-playlist{
    width: 150px;
    line-height: 45px;
    text-align: center;
    background: #f2494a;
    margin-left: 15px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
  }

  .typeahead-wrapper .name{
    flex: 1;
    height: 45px;
    padding: 0 15px;
    border: none;
    font-size: 14px;
    border: 1px solid #eaeaea;
  }

  .playlist-subtitle{
    text-transform: uppercase;
  }

  .subtitle-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .subtitle-wrapper .subtitle{
    text-transform: uppercase;
    margin-right: 25px;
  }

  .subtitle-wrapper .subtitle:last-child{
    margin-right: 0;
  }

  .new-playlist ul.list, ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    height: -moz-calc(100vh - 360px);
    height: -webkit-calc(100vh - 360px);
    height: calc(100vh - 360px);
    padding: 3px;
    margin-bottom: 20px;
  }

  .new-playlist ul.list{
    height: -moz-calc(100vh - 220px);
    height: -webkit-calc(100vh - 220px);
    height: calc(100vh - 220px);
  }

  .new-playlist ul.list, ul.playlistUpdated-list .playlist-title{
    font-size: 12px;
    font-weight: bold;
  }

  .new-playlist ul.list, ul.playlistUpdated-list .playlist-tracks{
    font-size: 10px;
    font-weight: bold;
  }

  .new-playlist ul.list li{
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 60px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
  }

  .new-playlist ul li .track-order{
    min-width: 60px;
    height: 60px;
    text-align: center;
    font-size: 12px;
    line-height: 60px;
    font-weight: 900
  }

  .new-playlist ul li img{
    width: 60px;
    cursor: move;
  }

  .new-playlist .track-controls{
    display: flex;
    flex: 1;
    align-items: center;
  }

  .new-playlist .track-controls .icon-wrapper,
  .new-playlist .track-controls .remove,
  .new-playlist .track-controls .copy {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    border-left: 1px solid #eaeaea;
  }

  .new-playlist .track-controls .icon-wrapper{
    border: none;
  }

  .new-playlist .track-controls .player{
    margin-right: 15px;
  }

  .new-playlist .track-controls .player .track-title{
    text-transform: capitalize;
    font-size: 12px;
    font-weight: bold;
  }

  .new-playlist .track-controls .player .track-artist,
  .new-playlist .track-controls .player .track-duration{
    font-size: 10px;
  }

  .wavesurfer{
    flex: 1;
  }

  .wavesurfer canvas{
    max-width: none;
  }

  ul.playlistUpdated-list li {
    list-style-type: none;
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
    height: 60px;
    align-items: center;
    border: 1px solid #eaeaea;
    cursor: pointer;
  }

  ul.playlistUpdated-list li .playlists-list-card-img {
    margin-right: 15px;
  }

  ul.playlistUpdated-list li .playlists-list-card-img img{
    height: 60px;
    width: auto;
  }

  .modal-wrapper label {
    margin-bottom: 0;
    font-weight: bold;
  }

  .modal-wrapper textarea {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
    border-color: #dddede;
    border-radius: 0px;
  }


  @media (max-width: 768px) {
  .playlistUpdated-list {
    display: none;
  }

  .typeahead-wrapper input {
    display: none;
  }

  .playlist-edit-component .title {
    font-size: 12px;
    margin-bottom: 10px !important;
  }

  .typeahead-wrapper .add-playlist {
    margin-left: 0;
  }

  .controls-wrapper .modal-btn,
  .controls-wrapper ul li {
    font-size: 10px;
    padding: 5px 10px;
  }

  .new-playlist .playlist-list-wrapper ul.list {
    margin-top: 20px;
    padding: 0 10px;
  }

  .track-img {
    display: none;
  }

  .track-duration {
    display: none;
  }

  .new-playlist ul li .track-order {
    min-width: 40px;
  }

  .new-playlist .track-controls .player .track-title {
    font-size: 10px;
  }
}

</style>
