import { render, staticRenderFns } from "./DownloadAudio.vue?vue&type=template&id=177d24ec&scoped=true"
import script from "./DownloadAudio.vue?vue&type=script&lang=js"
export * from "./DownloadAudio.vue?vue&type=script&lang=js"
import style0 from "./DownloadAudio.vue?vue&type=style&index=0&id=177d24ec&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "177d24ec",
  null
  
)

export default component.exports